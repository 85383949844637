const dashBorrowData = [
  {
    id: 1,
    vaultID: 1,
    vaultName: "My First Vault",
    asset: "XDC",
    collateralLocked: "100.74 XDC",
    collateral: 1000000.0,
    earnDebt: 1000000.0,
    profit: 1000.0,
    traffic: 4,
    star: false,
    haunter: true,
    liquidationPrice: "1000.00",
    debt: "2000.00STC",
    status: "Active",
    activity: "Deposited 2000.00 XDC",
    date: "may 02, 2023",
    time: "12:05 PM",
    txHash: "xdc7ef2be6...10eb",
  },
  {
    id: 2,
    vaultID: 2,
    vaultName: "Personal Vault",
    asset: "XDC",
    collateralLocked: "100.74 XDC",
    collateral: 1000000.0,
    earnDebt: 1000000.0,
    profit: 1000.0,
    traffic: 1,
    star: true,
    haunter: false,
    liquidationPrice: "1000.00",
    debt: "2000.00STC",
    status: "Partial Active",
    activity: "Deposited 2000.00 XDC",
    date: "may 03, 2023",
    time: "12:05 PM",
    txHash: "xdc7ef2be6...10eb",
  },
  {
    id: 3,
    vaultID: 6,
    vaultName: "Miscellaneous",
    asset: "XDC",
    collateralLocked: "100.74 XDC",
    collateral: 1000000.0,
    earnDebt: 1000000.0,
    profit: 1000.0,
    traffic: 4,
    star: false,
    haunter: true,
    liquidationPrice: "1000.00",
    debt: "2000.00STC",
    status: "Haunted",
    activity: "Deposited 2000.00 XDC",
    date: "may 04, 2023",
    time: "12:05 PM",
    txHash: "xdc7ef2be6...10eb",
  },

  {
    id: 4,
    vaultID: 8,
    vaultName: "Miscellaneous 2",
    asset: "XDC",
    collateralLocked: "100.74 XDC",
    collateral: 1000000.0,
    earnDebt: 1000000.0,
    profit: 1000.0,
    traffic: 2,
    star: false,
    haunter: false,
    liquidationPrice: "1000.00",
    debt: "2000.00STC",
    status: "Active",
    activity: "Opened new vault with id=01",
    date: "may 05, 2023",
    time: "01:05 PM",
    txHash: "xdc7ef2be6...10eb",
  },
  {
    id: 5,
    vaultID: 9,
    vaultName: "Mkb New Vault",
    asset: "XDC",
    collateralLocked: "100.74 XDC",
    collateral: 1000000.0,
    earnDebt: 1000000.0,
    profit: 1000.0,
    traffic: 5,
    star: true,
    haunter: true,
    liquidationPrice: "1000.00",
    debt: "2000.00STC",
    status: "Haunted",
    activity: "Deposited 7500.00 XDC",
    date: "Jan 07, 2023",
    time: "12:05 PM",
    txHash: "xdc7ef2be6...10eb",
  },
  {
    id: 6,
    vaultID: 9,
    vaultName: "Mkb New Vault",
    asset: "XDC",
    collateralLocked: "100.74 XDC",
    collateral: 1000000.0,
    earnDebt: 1000000.0,
    profit: 1000.0,
    traffic: 0,
    star: false,
    haunter: true,
    liquidationPrice: "1000.00",
    debt: "2000.00STC",
    status: "Haunted",
    activity: "Deposited 7500.00 XDC",
    date: "Jan 08, 2031",
    time: "12:05 PM",
    txHash: "xdc7ef2be6...10eb",
  },
  {
    id: 7,
    vaultID: 9,
    vaultName: "Mkb New Vault",
    asset: "XDC",
    collateralLocked: "100.74 XDC",
    collateral: 1000000.0,
    earnDebt: 1000000.0,
    profit: 1000.0,
    traffic: 1,
    star: true,
    haunter: false,
    liquidationPrice: "1000.00",
    debt: "2000.00STC",
    status: "Haunted",
    activity: "Deposited 7500.00 XDC",
    date: "Jan 09, 2023",
    time: "12:05 PM",
    txHash: "xdc7ef2be6...10eb",
  },
  {
    id: 8,
    vaultID: 9,
    vaultName: "Mkb New Vault",
    asset: "XDC",
    collateralLocked: "100.74 XDC",
    collateral: 1000000.0,
    earnDebt: 1000000.0,
    profit: 1000.0,
    traffic: 1,
    star: true,
    haunter: false,
    liquidationPrice: "1000.00",
    debt: "2000.00STC",
    status: "Haunted",
    activity: "Deposited 7500.00 XDC",
    date: "Jan 09, 2023",
    time: "12:05 PM",
    txHash: "xdc7ef2be6...10eb",
  },
  {
    id: 9,
    vaultID: 9,
    vaultName: "Mkb New Vault",
    asset: "XDC",
    collateralLocked: "100.74 XDC",
    collateral: 1000000.0,
    earnDebt: 1000000.0,
    profit: 1000.0,
    traffic: 1,
    star: true,
    haunter: false,
    liquidationPrice: "1000.00",
    debt: "2000.00STC",
    status: "Haunted",
    activity: "Deposited 7500.00 XDC",
    date: "may 10, 2023",
    time: "12:05 PM",
    txHash: "xdc7ef2be6...10eb",
  },
  {
    id: 10,
    vaultID: 9,
    vaultName: "Mkb New Vault",
    asset: "XDC",
    collateralLocked: "100.74 XDC",
    collateral: 1000000.0,
    earnDebt: 1000000.0,
    profit: 1000.0,
    traffic: 1,
    star: true,
    haunter: false,
    liquidationPrice: "1000.00",
    debt: "2000.00STC",
    status: "Haunted",
    activity: "Deposited 7500.00 XDC",
    date: "may 12, 2023",
    time: "12:05 PM",
    txHash: "xdc7ef2be6...10eb",
  },
];

export default dashBorrowData;
