const securityQuestions = [
  {
    id: 1,
    label: "Token Details",
    name: "token_details",
    question: [
      "- Select a Question -",
      "This is Question 1 here?",
      "What is the Token ID?",
    ],
  },
  {
    id: 2,
    label: "Personal Questions",
    name: "personal_questions",
    question: [
      "- Select a Question -",
      "This is Question 2 here?",
      "What is the Token ID?",
    ],
  },
  {
    id: 3,
    label: "Personal Questions",
    name: "personal_questions_2",
    question: [
      "- Select a Question -",
      "This is Question 3 here?",
      "What is the Token ID?",
    ],
  },
  {
    id: 4,
    label: "Beneficiary",
    name: "beneficiary",
    question: [
      "- Select a Question -",
      "This is Question 4 here?",
      "What is the Token ID?",
    ],
  },
];

export default securityQuestions;
