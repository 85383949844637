const reclaimToken = [
  {
    id: 1,
    label: "Token Details",
    question: ["This is Question 1 here?", "What is the Token ID?"],
    answer: "This is Question 1 Answer",
  },
  {
    id: 2,
    label: "Token Details",
    question: ["This is Question 2 here?", "What is the Token ID?"],
    answer: "This is Question 2 Answer",
  },
  {
    id: 3,
    label: "Token Details",
    question: ["This is Question 3 here?", "What is the Token ID?"],
    answer: "This is Question 3 Answer",
  },
];
export default reclaimToken;
