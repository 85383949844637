const tokenDetails = [
  {
    id: 1,
    title: "Token ID",
    content: "#01",
  },

  {
    id: 2,
    title: "Initial Owner",
    content: `xdc14344567uchjcio90eedrjhzus9idjicvo\niiahuiasooofcpkduu`,
  },
  {
    id: 3,
    title: "Current Owner",
    content: `xdc14344567uchjcio90eedrjhzus9idjicvo\niiahuiasooofcpkduu`,
  },
  {
    id: 4,
    title: "Status",
    content: "Secured",
  },
  {
    id: 5,
    title: "Beneficiary",
    content: `xdc14344567uchjcio90eedrjhzus9idjicvo\niiahuiasooofcpkduu`,
  },
  {
    id: 6,
    title: "Created On",
    content: "26 Dec, 2023",
  },
];

export default tokenDetails;
